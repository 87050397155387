import React from "react"
import { Link } from "gatsby"

import HeaderResources from "../../../components/header_resources"

export default function FooledByRandomness() {
  const heroTitle = "Fooled by Randomness – Nassim Nicholas Taleb (NNT)"

  return (
    <HeaderResources heroTitle={heroTitle} layout="1Column">
      <div class="w-100 mb-5 sm:mmb-5">
        <Link class="back--home" to="/resources/book-summaries">
          <i class="fas fa-chevron-left"></i> <span>Back to Book Summaries</span>
        </Link>
      </div>
      <div class="innerpage--content innerpage--content-sm blog--details m-0 p-0">
        <h2 class="h2 h2-sm">{heroTitle}</h2>
        <p class="text-uppercase">
          This one was a thought provoking book. The book opened up my mind even further to the role
          that probabilities, luck and randomness play in our lives (including the investing world)
          though Howard Marks in his book - The Most Important Thing - provided a fair bit of idea
          about it. I have set out below a summary, key takeaways / learning outcomes, some
          ramblings and unanswered questions.
        </p>
        <p>
          Begins with setting out the major thesis underlying the book which is that in a number of
          situations we enjoy presenting a lot of conjecture and randomness as truth or determinism
          and confuse luck with skill. The central theme that I could decipher is that eventually it
          is the emotional machinery rather than our cognitive apparatus that exerts meaningful
          control over our actions and a mere awareness of this fact can help us in protecting
          ourselves from blowing up during black swan events. Things that come with only a little
          help from luck are more resistant to randomness. People typically attribute success to
          skill and failure / losses to randomness.
        </p>
        <p>
          Skewness – it does not matter how frequently something succeeds if failure is too costly
          to bear. If the rare event can take back all that you have gained then you are not
          “anti-fragile”. The fragility of your investment style or your character was merely
          hidden.
        </p>
        <p>
          Alternative histories – one shouldn’t judge the performance by the results but by the
          costs of the alternative i.e. if the history had played out some other way. There are many
          possible worlds / outcomes that could occur, but the outcomes that have the highest
          occurrence in most possible worlds can be called as certain i.e. certainty is something
          that is likely to take place across the highest number of alternative histories and
          uncertainty concerns events that should take place in the lowest number of them.
        </p>
        <p>
          Accordingly, one needs to be protected against events that could occur (the uncertain /
          rare events) but their non-occurrence offers a sigh of relief. I liked the concept of
          questioning the “generator” of the visible outcome and NNT explains it with a good
          comparison between a guy who makes USD 1 million in Russian roulette vis-à-vis a dentist
          making USD 1 million through his skill – the quality behind the earnings is important!
        </p>
        <p>
          People usually do not like to insure against something abstract since their attention is
          focused on things / risks that are vivid or apparent.
        </p>
        <p>
          Being correct and being intelligent are two different things but are often confused with
          one another since if someone is proved to be correct she may be considered to be
          intelligent notwithstanding that correctness being generated by randomness. This is
          because when we look at the past, the past will always be deterministic, since only one
          single observation took place. Our mind will interpret most events not with the preceding
          ones in mind, but the following ones. One should take into account both visible and
          invisible outcomes. Also one needs to distil the information from the noise in order to be
          able to make an honest attempt at making correct decisions intelligently.
        </p>
        <h3 class="mt-4">
          In terms of investments, one can attempt to prevent from being fooled by randomness if
          one:
        </h3>
        <ol>
          <li>doesn’t overestimate the accuracy of one’s beliefs;</li>
          <li>
            doesn’t have a tendency to get married to one’s positions – as they say the stock
            doesn’t know that you own it and wouldn’t love you back;
          </li>
          <li>
            doesn’t tend to change the investment story i.e. become a long term investor if one
            loses money. For me it happens in the reverse. If the price runs up before adequate
            quantity is bought and I feel doesn’t make sense to buy any longer, I may become a
            trader from a long term investor and offload the position at the spiked up price;
          </li>
          <li>doesn’t have a plan ahead of time as to what to do in the event of losses;</li>
          <li>
            is in denial and doesn’t have a clear acceptance of what happened and why it happened.
          </li>
        </ol>
        <p>
          It is not how likely an event is to happen that matters, it is how much is made when it
          happens that should be the consideration. Making money infrequently from rare events is
          fine since mispricings arise primarily during such rare events.
        </p>
        <h3 class="mt-4">Some of the biases to tame or be aware about</h3>
        <ol>
          <li>
            survivorship bias – arising from a fact that we only see winners and get a distorted
            view of the odds;{" "}
          </li>
          <li>luck is more frequently the reason for extreme success; </li>
          <li>biological handicap of our inability to understand probability;</li>
          <li>anchoring bias.</li>
        </ol>
        <p>
          Also dwells on something that Cialdini had explained in his book - Influence - re mental
          shortcuts. Says that rules have their value and we follow them not because they are the
          best but because they are useful and save time and effort. However, we need to be aware
          and careful when to avoid following them. It is important to gauge something by the
          process and not by the results.
        </p>
        <h3 class="mt-4">Explains the two systems of reasoning:</h3>
        <ol>
          <li>
            System 1 is effortless, automatic, associative, rapid, parallel, emotional, concrete,
            specific, social
          </li>
          <li>
            System 2 is effortful, controlled, deductive, slow, self aware, neutral, abstract,
            asocial One needs to develop and use more of System 2 – particularly for making
            investment decisions – in order to prevent being fooled by randomness.
          </li>
        </ol>
        <p>
          Suggests becoming a stoic – as that’s the form of a person who combines the qualities of
          wisdom, upright dealing and courage and is immune from life’ gyrations. Lady fortuna has
          no control over our behavior and that is what we should keep in check and at its best in
          order to prevent being fooled by randomness.
        </p>
      </div>
    </HeaderResources>
  )
}
